import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "社内勉強会 TechLunch で Badging API について発表しました",
  "date": "2019-08-27T08:42:56.000Z",
  "slug": "entry/2019/08/27/174256",
  "tags": ["medley"],
  "hero": "./2019_08_27.png",
  "heroAlt": "badging api"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`みなさん、こんにちは。開発本部のエンジニアの舘野です。先日、社内勉強会「TechLunch」で Badging API について発表したので、その内容を紹介させていただきます。`}</p>
    <h1>{`Badging API とは`}</h1>
    <p>{`Badging API とは、ネイティブアプリのアプリアイコン上に表示されるバッジと同様に、ウェブアプリのアイコン上にバッジを表示することができる Web API です。`}</p>
    <p>{`ネイティブアプリで可能なこと全てをウェブアプリでも可能にすることを目指す、`}<a parentName="p" {...{
        "href": "https://www.chromium.org/teams/web-capabilities-fugu"
      }}>{`Fugu`}</a>{` というプロジェクトで実現に向けて動いている API の 1 つで、Chrome 73 から `}<a parentName="p" {...{
        "href": "https://github.com/GoogleChrome/OriginTrials"
      }}>{`Origin Trials`}</a>{` として利用可能になっています。Origin Trials とは、試験的に特定の開発者に限定して API を利用できるようにする仕組みのことで、正式リリース前に API に対する有用なフィードバックを受け取ることができるものです。`}</p>
    <p>{`この API の最新の概要や仕様については、 `}<a parentName="p" {...{
        "href": "https://wicg.io/"
      }}>{`WICG`}</a>{` が Github に `}<a parentName="p" {...{
        "href": "https://github.com/WICG/badging/"
      }}>{`Badging API のリポジトリ`}</a>{`を用意しているので、そこで確認することができます。`}</p>
    <p>{`WICG（The Web Incubator Community Group）は、先進的なウェブ技術について検討するコミュニティグループで、W3C のグループの 1 つです。`}</p>
    <p>{`提案されている API のインターフェースは、現時点(2019/08/14)では以下のようになっています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/WICG/badging/blob/master/explainer.md#the-api"
      }}>{`https://github.com/WICG/badging/blob/master/explainer.md#the-api`}</a></p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Badge`}</code>{`を`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`window`}</code>{`オブジェクトのメンバとして持つ`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Badge`}</code>{`には 2 つのメソッドが存在する`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`Badge.set()`}</code></li>
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`Badge.clear()`}</code></li>
        </ul>
      </li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Badge.set(5)`}</code>{`のように`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`set()`}</code>{`に整数を渡してバッジ上に数字を表示する`}</li>
      <li parentName="ul">{`単に`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Badge.set()`}</code>{`で呼び出すとフラグとしてバッジを表示する`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Badge.set(5, { scope: ‘/baz’ })`}</code>{`のようにオプションを渡して特定のスコープ配下で表示されるように指定できる`}
        <ul parentName="li">
          <li parentName="ul">{`オプションでスコープが指定されてない場合、スコープは`}<code parentName="li" {...{
              "className": "language-text"
            }}>{`/`}</code>{`になる`}</li>
        </ul>
      </li>
    </ul>
    <h1>{`ローカル環境で試す`}</h1>
    <p>{`実際にどのような形でバッジを表示できるかを確認するために、今回はローカル環境（macOS 10.14、Chrome76）で試してみました。`}</p>
    <p>{`API 自体は非常にシンプルなので、PWA のアプリを用意してインストールするだけで簡単に試すことができます。`}</p>
    <p>{`インストールされていないウェブアプリでも、タブの favicon 上やブックマークアイコン上にバッジを表示することも議論されているようですが、今のところインストール済みのウェブアプリでしかバッジは表示されません。`}</p>
    <p>{`最初に API を利用可能な状態にする必要がありますが、上述の通り API 自体が現在 Origin Trials の段階なので、Origin Trials の利用申請を行うかローカル環境であれば chrome://flags で実験的な機能を有効にする（#enable-experimental-web-platform-features）かのいずれかを行う必要があります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190827/20190827173844.png",
      "alt": "20190827173844.png"
    }}></img>
    <p>{`今回はローカル環境で試すだけなので、chrome://flags から enable-experimental-web-platform-features を有効にしておきます。`}</p>
    <p>{`実験的な機能を利用可能にしたことで Badging API 自体は利用可能になりますが、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`window.Badge`}</code>{`として利用可能になっているのではなく、Origin Trials の段階では`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Badge`}</code>{`ではなく`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ExperimentalBadge`}</code>{`として提供されています。`}</p>
    <p>{`次に、サンプルのプロジェクトを用意して webpack-dev-server でローカルサーバを用意します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` init

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` --dev webpack webpack-cli webpack-dev-server html-webpack-plugin copy-webpack-plugin
`}</code></pre></div>
    <p>{`webpack-dev-server でローカルサーバが見れる状態になるように webpack.config.js に設定を記述します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` path `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"path"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` HtmlWwebpackPlugin `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"html-webpack-plugin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`exports `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`mode`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"development"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`devServer`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`https`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`entry`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"./src/js/app.js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`output`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`path`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`__dirname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"./dist"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`plugins`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`HtmlWwebpackPlugin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`template`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"src/index.html"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`次に manifest.json を用意します。manifest.json は、そのアプリがどういったものか、また、インストールした時にどのように振る舞うかをブラウザに伝えるための設定ファイルになります。`}</p>
    <p><a parentName="p" {...{
        "href": "https://app-manifest.firebaseapp.com/"
      }}>{`https://app-manifest.firebaseapp.com/`}</a>{` のようなサービスで manifest.json とアイコンを各種サイズ自動生成できるので、今回はこのサービスで生成します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"badging-api-playground"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"short_name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"badge"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"theme_color"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"#5B5CFD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"background_color"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"#5B5CFD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"display"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"standalone"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"orientation"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"portrait"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"prefer_related_applications"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"Scope"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"start_url"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"icons"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`

    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"src"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"images/icons/icon-72x72.png"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"sizes"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"72x72"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"image/png"`}</span>{`

    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"src"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"images/icons/icon-96x96.png"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"sizes"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"96x96"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"image/png"`}</span>{`

    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"src"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"images/icons/icon-128x128.png"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"sizes"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"128x128"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"image/png"`}</span>{`

    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"src"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"images/icons/icon-144x144.png"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"sizes"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"144x144"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"image/png"`}</span>{`

    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

    ….

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"splash_pages"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p>{`webpack.config.js の方に manifest.json をローカルサーバで配信されるように設定を追加しておきます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}>{`const path = require('path')

const HtmlWwebpackPlugin = require('html-webpack-plugin')

`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` const CopyPlugin = require('copy-webpack-plugin')
`}</span>{`


module.exports = {

  ….

  plugins: [

    ….

`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`     new CopyPlugin([
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`       {
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`         from: 'src/manifest.json',
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`         to: '',
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`       },
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`       {
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`         from: 'src/images/icons',
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`         to: 'images/icons/'
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`       },
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`     ]),
`}</span>{`
  ],

}
`}</code></pre></div>
    <p>{`ここまでで Chrome の Application タブから manifest.json が認識されいてることが確認できますが、インストール可能な状態にはなっていません。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190826/20190826185511.png",
      "alt": "20190826185511.png"
    }}></img>
    <p>{`アプリをインストール可能な状態にするには `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/fundamentals/app-install-banners/#criteria"
      }}>{`いくつかの基準`}</a>{` があり、service worker が必要になります。今回は`}<a parentName="p" {...{
        "href": "https://developers.google.com/web/tools/workbox/"
      }}>{`Workbox`}</a>{` の webpack プラグインで対応します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` --dev workbox-webpack-plugin
`}</code></pre></div>
    <p>{`workbox には GenerateSW と injectManifest の 2 つのモードがあり、今回はどちらでも問題ないかと思いますが injectManifest モードを利用します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "diff"
    }}><pre parentName="div" {...{
        "className": "language-diff"
      }}><code parentName="pre" {...{
          "className": "language-diff"
        }}>{`const path = require('path')

const HtmlWwebpackPlugin = require('html-webpack-plugin')

const CopyPlugin = require('copy-webpack-plugin')

`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{` const { InjectManifest } = require('workbox-webpack-plugin')
`}</span>{`


module.exports = {

  ….

  plugins: [

    ….

`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`     new InjectManifest({
`}</span>{`


`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`       swSrc: path.resolve(__dirname, 'src/sw.js'),
`}</span>{`
`}<span parentName="code" {...{
            "className": "token inserted-sign inserted"
          }}><span parentName="span" {...{
              "className": "token prefix inserted"
            }}>{`+`}</span>{`     }),
`}</span>{`
  ],

}
`}</code></pre></div>
    <p>{`app.js の方で service worker の登録がされるように記述しておきます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"serviceWorker"`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"load"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`serviceWorker
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`register`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"./sw.js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`res`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`res`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`catch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`err`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`err`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`service worker の対応が済むとインストール可能なアプリの基準を満たすので、Chrome76 ではアドレスバーにオムニボックスが表示され、そこからインストールが可能になっています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190826/20190826185538.png",
      "alt": "20190826185538.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190826/20190826185554.png",
      "alt": "20190826185554.png"
    }}></img>
    <p>{`インストールすると Launchpad にアプリアイコンが表示されたので、実際に Badge API を試してみます。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`window.ExperimentalBadge.set()`}</code>{`を呼び出すと、フラグとしてバッジがつきます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190826/20190826185622.png",
      "alt": "20190826185622.png"
    }}></img>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`window.ExperimentalBadge.set(1)`}</code>{`のように引数に数値を入れて呼び出すと、バッジは数字が入った状態で表示されます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190826/20190826185641.png",
      "alt": "20190826185641.png"
    }}></img>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`window.ExperimentalBadge.clear()`}</code>{`でバッジがクリアされ、元のアプリアイコンだけの状態に戻ります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190826/20190826185653.png",
      "alt": "20190826185653.png"
    }}></img>
    <p>{`非常に簡単ではありますが、このようにしてウェブアプリのアイコンにバッジをつけられることが確認できました。`}</p>
    <p>{`なお、このサンプルプロジェクトは `}<a parentName="p" {...{
        "href": "https://github.com/makotot/badging-api-playground"
      }}>{`https://github.com/makotot/badging-api-playground`}</a>{` にあげてあります。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`近い将来正式リリースされる可能性が高い API を試すことで、今後ウェブアプリでどのようなことが実現可能になっていくかの一端を垣間見ることができました。`}</p>
    <p>{`API 自体が Origin Trial の段階で、ブラウザのタブの favicon 上やブックマークアイコン上に表示したいケースであったり、バッジはどこまでの範囲で適用するべきかのスコープの問題であったり、最終的にどのような形に仕様が整理されるかまだ明確ではない部分もあります。`}</p>
    <p>{`最終的にどのように課題が解決されていくか注目したいと思います。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      